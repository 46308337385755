import React, { useEffect, useRef, MutableRefObject } from 'react';
import { GeocoderAutocomplete, LocationType, SupportedLanguage, GeoPosition, CountyCode, ByCountryCodeOptions, ByCircleOptions, ByRectOptions, ByProximityOptions } from '@geoapify/geocoder-autocomplete';

export const GeoapifyApiKey = React.createContext<string>('');

export const GeoapifyContext = (props: any) => {
  return <GeoapifyApiKey.Provider value={props.apiKey}>
    {props.children}
  </GeoapifyApiKey.Provider>
}

export interface GeoapifyGeocoderAutocompleteOptions {
  value?: string;
  type?: LocationType;
  lang?: SupportedLanguage;
  limit?: number;
  placeholder?: string;
  filterByCountryCode?: ByCountryCodeOptions,
  filterByCircle?: ByCircleOptions,
  filterByRect?: ByRectOptions,
  biasByCountryCode?: ByCountryCodeOptions,
  biasByCircle?: ByCircleOptions,
  biasByRect?: ByRectOptions,
  biasByProximity?: ByProximityOptions,
  position?: GeoPosition,
  countryCodes?: CountyCode[],

  placeSelect: (value: any) => {};
  suggestionsChange: (value: any) => {}
}

export const GeoapifyGeocoderAutocomplete = ({ placeholder: placeholderValue,
  type: typeValue,
  lang: langValue,
  limit: limitValue,
  value: valueValue,
  filterByCountryCode: filterByCountryCodeValue,
  filterByCircle: filterByCircleValue,
  filterByRect: filterByRectValue,
  biasByCountryCode: biasByCountryCodeValue,
  biasByCircle: biasByCircleValue,
  biasByRect: biasByRectValue,
  biasByProximity: biasByProximityValue,
  position: positionValue,
  countryCodes: countryCodesValue,

  placeSelect: placeSelectCallback,
  suggestionsChange: suggestionsChangeCallback}: GeoapifyGeocoderAutocompleteOptions) => {
  const apiKey = React.useContext<string>(GeoapifyApiKey);
  let geocoderContainer: HTMLDivElement | null;
  let initialized: boolean = false;
  let geocoderAutocomplete: MutableRefObject<GeocoderAutocomplete | undefined> = useRef<GeocoderAutocomplete>();

  function onSelect(value: any) {
    if (placeSelectCallback) {
        placeSelectCallback(value);
    }
  }

  function onSuggestions(value: any) {
    if (suggestionsChangeCallback) {
      suggestionsChangeCallback(value);
    }
  }

  useEffect(() => {
    if (initialized) {

      if (geocoderAutocomplete.current) {
        geocoderAutocomplete.current.off('select', onSelect);
        geocoderAutocomplete.current.off('suggestions', onSuggestions);
      }

      return;
    }

    initialized = true;

    geocoderAutocomplete.current = new GeocoderAutocomplete(geocoderContainer as HTMLDivElement, apiKey, {
      placeholder: placeholderValue || ''
    });

    geocoderAutocomplete.current.on('select', onSelect);
    geocoderAutocomplete.current.on('suggestions', onSuggestions);
  }, []);

  useEffect(() => {
    if (geocoderAutocomplete.current) {
      geocoderAutocomplete.current.setType(typeValue as LocationType);
    }
  }, [typeValue]);

  useEffect(() => {
    if (geocoderAutocomplete.current) {
      geocoderAutocomplete.current.setLang(langValue as SupportedLanguage);
    }
  }, [langValue]);

  useEffect(() => {
    if (geocoderAutocomplete.current) {
      console.warn("WARNING! Obsolete function called. The  'position' input has been deprecated, please use the new 'biasByLocation' input instead!");
      geocoderAutocomplete.current.addBiasByProximity(positionValue as GeoPosition);
    }
  }, [positionValue]);

  useEffect(() => {
    if (geocoderAutocomplete.current) {
      console.warn("WARNING! Obsolete function called. The  'countryCodes' input has been deprecated, please use the new 'filterByCountryCode' input instead!");
      geocoderAutocomplete.current.addFilterByCountry(countryCodesValue as CountyCode[]);
    }
  }, [countryCodesValue]);

  useEffect(() => {
    if (geocoderAutocomplete.current) {
      geocoderAutocomplete.current.setLimit(limitValue as number);
    }
  }, [limitValue]);

  useEffect(() => {
    if (geocoderAutocomplete.current) {
      geocoderAutocomplete.current.setValue(valueValue as string || '');
    }
  }, [valueValue]);

  useEffect(() => {
    if (geocoderAutocomplete.current) {
      geocoderAutocomplete.current.addFilterByCountry(filterByCountryCodeValue as ByCountryCodeOptions);
    }
  }, [filterByCountryCodeValue]);

  useEffect(() => {
    if (geocoderAutocomplete.current) {
      geocoderAutocomplete.current.addFilterByCircle(filterByCircleValue as ByCircleOptions);
    }
  }, [filterByCircleValue]);

  useEffect(() => {
    if (geocoderAutocomplete.current) {
      geocoderAutocomplete.current.addFilterByRect(filterByRectValue as ByRectOptions);
    }
  }, [filterByRectValue]);

  useEffect(() => {
    if (geocoderAutocomplete.current) {
      geocoderAutocomplete.current.addBiasByCountry(biasByCountryCodeValue as ByCountryCodeOptions);
    }
  }, [biasByCountryCodeValue]);

  useEffect(() => {
    if (geocoderAutocomplete.current) {
      geocoderAutocomplete.current.addBiasByCircle(biasByCircleValue as ByCircleOptions);
    }
  }, [biasByCircleValue]);

  useEffect(() => {
    if (geocoderAutocomplete.current) {
      geocoderAutocomplete.current.addBiasByRect(biasByRectValue as ByRectOptions);
    }
  }, [biasByRectValue]);

  useEffect(() => {
    if (geocoderAutocomplete.current) {
      geocoderAutocomplete.current.addBiasByProximity(biasByProximityValue as ByProximityOptions);
    }
  }, [biasByProximityValue]);

  return <div className="geocoder-container" style={{ 'position': 'relative' }} ref={el => geocoderContainer = el}></div>
}